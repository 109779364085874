import * as React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Button,
  Container,
  Paper,
  TextField,
  Typography
} from '@material-ui/core';
import { NeedSignIn } from '../SignInDialog';
import { ErrorBoundary } from '../ErrorBoundary';
import { LoadingPage } from '../LoadingPage';
import { style } from 'typestyle';
import { getAuthUser } from '../../ducks/auth';
import { useFirestore } from '../../hooks/useFirestore';
import { noticeAtom } from '../NoticeManager';
import { useSetRecoilState, useRecoilState } from 'recoil';
import { agentPromptAtom } from '../../recoil/agentPromptAtom';
import { DEFAULT_PROMPT } from '../../constants/defaultPrompt';
import { I, JA, EN } from '../lang';

const cn = {
  root: style({
    padding: '24px 0'
  }),
  paper: style({
    padding: 24,
    marginBottom: 24
  }),
  textField: style({
    marginTop: 16,
    marginBottom: 16,
    width: '100%'
  }),
  menu: style({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 16
  })
};

export function AgentSettingsPage() {
  return (
    <ErrorBoundary fallback={<NeedSignIn />}>
      <React.Suspense fallback={<LoadingPage />}>
        <AuthUserAgentSettingsPage />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function AuthUserAgentSettingsPage() {
  const { id } = useParams<{ id: string }>();
  const authUser = useSelector(getAuthUser);
  const [loading, setLoading] = React.useState(true);
  const [saving, setSaving] = React.useState(false);
  const firestore = useFirestore();
  const setNotice = useSetRecoilState(noticeAtom);
  const [prompt, setPrompt] = useRecoilState(agentPromptAtom);
  const maxPromptLength = 2000;
  const currentPromptLength = prompt.length;

  React.useEffect(() => {
    if (!authUser || !id) return;

    const fetchAgent = async () => {
      try {
        const doc = await firestore().collection('agents').doc(id).get();
        if (!doc.exists) {
          setNotice({
            severity: 'error',
            children: (
              <I
                ja="指定されたエージェントは存在しません"
                en="The specified agent does not exist"
              />
            )
          });
          return;
        }
        if (doc.data()?.uid !== authUser.uid) {
          setNotice({
            severity: 'error',
            children: (
              <I
                ja="このエージェントにアクセスする権限がありません"
                en="You do not have permission to access this agent"
              />
            )
          });
          return;
        }
        setPrompt(doc.data()?.prompt || '');
      } catch (error) {
        console.error('Failed to fetch agent:', error);
        setNotice({
          severity: 'error',
          children: (
            <I
              ja="エージェントの読み込みに失敗しました"
              en="Failed to load the agent"
            />
          )
        });
      } finally {
        setLoading(false);
      }
    };

    fetchAgent();
  }, [authUser?.uid, id]);

  if (!authUser) {
    return <NeedSignIn />;
  }

  if (loading) {
    return null;
  }

  const handleSave = async () => {
    if (saving) return;
    setSaving(true);

    try {
      await firestore().collection('agents').doc(id).update({
        prompt: prompt,
        updatedAt: firestore.FieldValue.serverTimestamp()
      });
      // Recoil state is already updated through the TextField onChange
      // Just show success message
      setNotice({
        severity: 'success',
        children: (
          <I
            ja="エージェントのプロンプトを更新しました"
            en="Agent prompt has been updated"
          />
        )
      });
    } catch (error) {
      console.error('Failed to update agent:', error);
      setNotice({
        severity: 'error',
        children: (
          <I
            ja="エージェントの更新に失敗しました"
            en="Failed to update the agent"
          />
        )
      });
    } finally {
      setSaving(false);
    }
  };

  return (
    <Container className={cn.root}>
      <Paper className={cn.paper}>
        <Typography variant="h4" gutterBottom>
          <I ja="AIエージェントの設定" en="AI Agent Settings" />
        </Typography>
        <Typography variant="body1" paragraph>
          <JA>
            AIエージェントの性格や役割を設定できます。プロンプトを変更することで、AIの応答が変化します。
            <br />
            個人情報は書かないようにしてください。このプロンプトは他のユーザーや運営者に公開されます。
          </JA>
          <EN>
            You can set the personality and role of your AI Agent. The AI's
            responses will change based on the prompt.
            <br />
            Please do not include any personal information. This prompt will be
            visible to other users and administrators.
          </EN>
        </Typography>
        <TextField
          label={<I ja="プロンプト" en="Prompt" />}
          multiline
          maxRows={30}
          variant="outlined"
          value={prompt}
          onChange={e => setPrompt(e.target.value)}
          className={cn.textField}
          placeholder={
            navigator.language.startsWith('ja')
              ? '例: あなたはプログラミング学習をサポートするアシスタントです。わかりやすい言葉で説明してください。'
              : 'Example: You are an assistant who supports programming learning. Please explain in easy-to-understand terms.'
          }
          helperText={`${currentPromptLength}/${maxPromptLength}`}
          error={currentPromptLength > maxPromptLength}
        />
        <div className={cn.menu}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setPrompt(DEFAULT_PROMPT)}
          >
            <I ja="デフォルトに戻す" en="Reset to Default" />
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={saving || currentPromptLength > maxPromptLength}
          >
            {saving ? (
              <I ja="保存中..." en="Saving..." />
            ) : (
              <I ja="保存" en="Save" />
            )}
          </Button>
        </div>
      </Paper>
    </Container>
  );
}
