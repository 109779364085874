import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { NeedSignIn } from '../SignInDialog';
import { ErrorBoundary } from '../ErrorBoundary';
import { LoadingPage } from '../LoadingPage';
import { getAuthUser } from '../../ducks/auth';
import { useFirestore } from '../../hooks/useFirestore';

export function AgentsRedirect() {
  return (
    <ErrorBoundary fallback={<NeedSignIn />}>
      <React.Suspense fallback={<LoadingPage />}>
        <AuthUserAgentsRedirect />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function AuthUserAgentsRedirect() {
  const [agentId, setAgentId] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(true);
  const authUser = useSelector(getAuthUser);
  const firestore = useFirestore();

  React.useEffect(() => {
    if (!authUser) return;

    const fetchAgent = async () => {
      const snapshot = await firestore()
        .collection('agents')
        .where('uid', '==', authUser.uid)
        .get();
      if (!snapshot.empty) {
        setAgentId(snapshot.docs[0].id);
      }
      setLoading(false);
    };

    fetchAgent();
  }, [authUser]);

  if (!authUser) {
    return <NeedSignIn />;
  }

  if (loading) {
    return null;
  }

  return agentId ? (
    <Redirect to={`/agents/${agentId}`} />
  ) : (
    <Redirect to="/agents/new" />
  );
}
