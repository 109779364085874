import type { AppType } from '@hackforplay/edge';
import { hc } from 'hono/client';
import { throttle } from 'lodash-es';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRecoilValue } from 'recoil';

import { getAuthUser } from '../../ducks/auth';
import firebase from '../../settings/firebase';
import { ChatMessage, Message } from './ChatMessage';
import { Draggable } from './Draggable';
import { agentPromptAtom } from '../../recoil/agentPromptAtom';

const firestore = firebase.firestore;

const endpoint = process.env.REACT_APP_CLOUDRUN_ENDPOINT;
const client = hc<AppType>(new URL(endpoint!).origin);

export function ChatContainer() {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [intermidiateMessage, setIntermidiateMessage] = React.useState('');
  const [processing, setProcessing] = React.useState(false);

  const scrollRef = React.useRef<HTMLDivElement>(null);
  const enableAutoScrollRef = React.useRef(true);
  const scrollToBottom = () => {
    window.requestAnimationFrame(() => {
      scrollRef.current?.scrollTo({
        top: scrollRef.current?.scrollHeight
      });
    });
  };
  const enableAutoScroll = () => {
    enableAutoScrollRef.current = true;
    scrollToBottom();
  };
  const previousScrollTop = React.useRef(0);
  const handleScroll = () => {
    const nextScrollTop = scrollRef.current?.scrollTop ?? 0;
    if (nextScrollTop < previousScrollTop.current) {
      enableAutoScrollRef.current = false; // 少しでも戻したら自動スクロールを無効化
    }
    previousScrollTop.current = nextScrollTop;
  };

  const authUser = useSelector(getAuthUser);
  const [aiChatParams, setAiChatParams] = useState(
    authUser?.experimentalAiChat
  );
  const agentPrompt = useRecoilValue(agentPromptAtom);

  // TODO: SWRかjotaiを使う
  const getThreadRef = useRef(false);
  useEffect(() => {
    if (!authUser) return;
    if (getThreadRef.current) return;
    getThreadRef.current = true;

    if (authUser.experimentalAiChat) {
      setAiChatParams(authUser.experimentalAiChat);
    } else {
      client.api.threads
        .$post({
          json: {
            uid: authUser.uid
          }
        })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to create a thread');
          }
          return response.json();
        })
        .then(result => {
          if (!result.threadId) {
            throw new Error('Failed to create a thread');
          }
          setAiChatParams(result);

          return firestore().collection('users').doc(authUser.uid).update({
            experimentalAiChat: result
          });
        })
        .catch(error => {
          window.Rollbar?.error(error);
          console.error(error);
          throw error;
        });
    }
  }, [authUser]);
  useEffect(() => {
    if (!aiChatParams) return;

    client.api.chats
      .$get({
        query: aiChatParams
      })
      .then(response => response.json())
      .then(result => {
        const messages = result?.messages.data ?? [];
        messages.sort((a, b) => a.created_at - b.created_at); // sort by created_at

        setMessages(
          messages.map((message, index) => ({
            role: message.role,
            content:
              message.content[0].type === 'text'
                ? message.content[0].text.value
                : ''
          })) ?? []
        );
        enableAutoScroll();
      })
      .catch(error => {
        window.Rollbar?.error(error);
        console.error(error);
        throw error;
      });
  }, [aiChatParams]);

  const sendNewMessage = async () => {
    const message = textareaRef.current?.value;
    if (!message) return;

    setMessages(messages => [...messages, { role: 'user', content: message }]);
    textareaRef.current.value = '';
    enableAutoScroll();

    const res = await client.api.chats.$post({
      json: {
        ...aiChatParams,
        message,
        userName: authUser?.displayName,
        userIcon: authUser?.iconUrl,
        ...(agentPrompt ? { instructions: agentPrompt } : {})
      }
    });
    if (!res.ok) {
      throw new Error('Failed to send a message');
    }

    const reader = res.body?.getReader();
    const decoder = new TextDecoder();
    let chatHistory = '';

    const scrollToBottomIfNeeded = throttle(() => {
      if (enableAutoScrollRef.current) {
        scrollToBottom();
      }
    }, 100);

    if (reader) {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        chatHistory += decoder.decode(value, { stream: true });
        setIntermidiateMessage(chatHistory);
        scrollToBottomIfNeeded();
      }
      setIntermidiateMessage('');
      setMessages(messages => [
        ...messages,
        { role: 'assistant', content: chatHistory }
      ]);
    }
  };

  const [opened, setOpened] = React.useState(false);
  const handleIconClick = () => {
    setOpened(!opened);
    scrollToBottom();
  };

  const mascotIconImageSrc =
    'https://i.gyazo.com/f77828e328fe038ad4f1d554620411ff.png';

  const maySingleClickRef = React.useRef(true);
  const mascotIcon = (
    <img
      src={mascotIconImageSrc}
      draggable="false"
      alt="AI"
      style={{
        width: opened ? 24 : 64
      }}
      onPointerDown={event => {
        if (event.button !== 0) {
          return;
        }
        maySingleClickRef.current = true;
      }}
      onPointerMove={() => {
        maySingleClickRef.current = false;
      }}
      onPointerUp={() => {
        if (maySingleClickRef.current) {
          handleIconClick();
        }
      }}
    />
  );

  if (!opened) {
    return <Draggable>{mascotIcon}</Draggable>;
  }

  return (
    <Draggable>
      {mascotIcon}
      <div
        className="ai-chat-container"
        style={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: 8,
          boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
          width: 440,
          minHeight: 600,
          maxHeight: 600
        }}
      >
        <div
          style={{
            flex: 1,
            overflowY: 'auto',
            padding: '16px 8px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            gap: 8
          }}
          ref={scrollRef}
          onScroll={handleScroll}
        >
          {messages.map((message, index) => (
            <ChatMessage key={index} message={message} />
          ))}
          {intermidiateMessage && (
            <ChatMessage
              message={{ role: 'assistant', content: intermidiateMessage }}
            />
          )}
        </div>
        <form
          onSubmit={event => {
            event.preventDefault();
            setProcessing(true);
            sendNewMessage()
              .catch(error => {
                window.Rollbar?.error(error);
                console.error(error);
                throw error;
              })
              .finally(() => {
                setProcessing(false);
              });
          }}
          style={{
            display: 'flex',
            alignItems: 'end',
            padding: '0 8px 8px 8px',
            gap: 4
          }}
        >
          <textarea
            name="input"
            ref={textareaRef}
            style={{ flex: 1, marginTop: 'auto' }}
          ></textarea>
          <button type="submit" disabled={processing}>
            Send
          </button>
        </form>
      </div>
    </Draggable>
  );
}
