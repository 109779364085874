import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Container, Typography, Paper } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { style } from 'typestyle';
import { NeedSignIn } from '../SignInDialog';
import { ErrorBoundary } from '../ErrorBoundary';
import { LoadingPage } from '../LoadingPage';
import { getAuthUser } from '../../ducks/auth';
import { useFirestore } from '../../hooks/useFirestore';
import { DEFAULT_PROMPT } from '../../constants/defaultPrompt';
import { I, JA, EN } from '../lang';

const cn = {
  root: style({
    padding: '24px 0'
  }),
  paper: style({
    padding: 24,
    marginBottom: 24
  }),
  button: style({
    marginTop: 16
  })
};

export function NewAgentPage() {
  return (
    <ErrorBoundary fallback={<NeedSignIn />}>
      <React.Suspense fallback={<LoadingPage />}>
        <AuthUserNewAgentPage />
      </React.Suspense>
    </ErrorBoundary>
  );
}

function AuthUserNewAgentPage() {
  const authUser = useSelector(getAuthUser);
  const history = useHistory();
  const [creating, setCreating] = React.useState(false);
  const [error, setError] = React.useState<React.ReactNode>(null);
  const firestore = useFirestore();

  if (!authUser) {
    return <NeedSignIn />;
  }

  const handleCreate = async () => {
    if (creating) return;
    setCreating(true);

    try {
      const existingSnapshot = await firestore()
        .collection('agents')
        .where('uid', '==', authUser.uid)
        .get();

      if (!existingSnapshot.empty) {
        setError(
          <I
            ja="すでにAIエージェントが存在するため、新しいエージェントは作成できません。"
            en="You already have an AI Agent. You cannot create multiple agents."
          />
        );
        setCreating(false);
        return;
      }

      const defaultPrompt = DEFAULT_PROMPT;

      const doc = await firestore().collection('agents').add({
        uid: authUser.uid,
        prompt: defaultPrompt,
        createdAt: firestore.FieldValue.serverTimestamp()
      });
      history.push(`/agents/${doc.id}`);
    } catch (error) {
      console.error('Failed to create agent:', error);
      setCreating(false);
    }
  };

  return (
    <Container className={cn.root}>
      <Paper className={cn.paper}>
        <Typography variant="h4" gutterBottom>
          <I ja="AIエージェントを作成" en="Create AI Agent" />
        </Typography>
        <Typography variant="body1" paragraph>
          <JA>
            あなただけのAIエージェントを作成できます。プロンプトを設定することで、AIの性格や役割を自由にカスタマイズできます。
          </JA>
          <EN>
            Create your own AI Agent. You can customize its personality and role
            by setting a prompt.
          </EN>
        </Typography>
        <Typography variant="body1" paragraph>
          <JA>作成したAIエージェントは、全てのユーザーに公開されます。</JA>
          <EN>Your AI Agent will be visible to all users.</EN>
        </Typography>
        {error && (
          <Alert severity="error" className={cn.button}>
            {error}
          </Alert>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreate}
          disabled={creating}
          className={cn.button}
        >
          {creating ? (
            <I ja="作成中..." en="Creating..." />
          ) : (
            <I ja="AIエージェントを作成" en="Create AI Agent" />
          )}
        </Button>
      </Paper>
    </Container>
  );
}
