import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Divider,
  makeStyles,
  MenuItem,
  Popover,
  Slide,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  Build,
  Home,
  People,
  Person,
  VideogameAsset
} from '@material-ui/icons';
import classNames from 'classnames';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { selector, useRecoilValue } from 'recoil';
import { media, style } from 'typestyle';
import { actions } from '../ducks/actions';
import { getAuthUser } from '../ducks/auth';
import Beginner from '../icons/Beginner';
import { notificationAtom } from '../recoil';
import { Link } from '../utils/components';
import { EN, I, JA } from './lang';
import { unreadIdAtom } from './Notification';
import { SignInDialog } from './SignInDialog';

export const defaultIconUrl = `${process.env.REACT_APP_FIREBASE_IMG_BUCKET_UGC}/default/icon.png`;

const cn = {
  root: style(
    { flex: '0 0 56px', zIndex: 1200 },
    media(
      { maxWidth: 0, orientation: 'landscape' },
      { flex: '0 0 48px', opacity: 3 }
    ), // media query が違うのに 同じ className を出力している,
    media({ minWidth: 600 }, { flex: '0 0 64px', opacity: 4 }) // media query が違うのに 同じ className を出力している
  ),
  toolbar: style({
    backgroundColor: grey[900],
    maxHeight: 64,
    $nest: {
      '& svg': {
        marginRight: 4
      }
    }
  }),
  blank: style({
    flex: 1
  }),
  icon: style({
    width: 18,
    marginRight: 12
  }),
  logo: style(
    { color: 'black', filter: 'invert(100%)' },
    // 画面幅が一定以下の時はロゴを表示しない
    media({ maxWidth: 721 }, { display: 'none', opacity: 5 }) // media query が違うのに 同じ className を出力している
  ),
  separator: style(
    {
      position: 'relative',
      display: 'inline-flex',
      minHeight: 36,
      alignSelf: 'center',
      $nest: {}
    },
    media(
      { minWidth: 920 },
      {
        $nest: {
          '&::before': {
            // button label が表示されるときだけ separator を表示する
            content: '""',
            position: 'absolute',
            left: 0,
            backgroundColor: 'white',
            width: 1,
            height: 36
          }
        },
        opacity: 6 // media query が違うのに 同じ className を出力している
      }
    )
  ),
  select: style(
    {
      marginLeft: 8,
      marginRight: 8,
      color: 'white'
    },
    // 画面幅が一定以下の時は切り替えメニューを表示しない
    media({ maxWidth: 561 }, { display: 'none', opacity: 7 }) // media query が違うのに 同じ className を出力している
  ),
  selectIcon: style({
    color: 'white'
  }),
  // button label が表示されるときは tooltip を表示しない
  tooltip: style(media({ minWidth: 920 }, { display: 'none', opacity: 1 })), // media query が違うのに 同じ className を出力している
  // 画面幅が一定以上の時だけ button label を表示する
  buttonLabel: style(
    media({ maxWidth: 921 }, { display: 'none', opacity: 2 }) // media query が違うのに 同じ className を出力している
  ),
  // 実質的なフルスクリーンモード
  rootSmall: style({
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1200,
    padding: '2px 2px 4px 4px',
    backgroundColor: 'rgba(0,0,0,0.6)',
    display: 'flex',
    alignItems: 'center',
    borderBottomLeftRadius: 4,
    opacity: 0.8,
    $nest: {
      '&:hover': {
        opacity: 1
      }
    }
  }),
  contrastButton: style({
    color: 'white'
  })
};

export function Header({}) {
  const auth = useSelector(state => state.auth);
  const location = useLocation();
  const isInOfficialWork = location.pathname.startsWith('/officials/');
  const isMaking = isInOfficialWork || location.pathname.startsWith('/works/');

  const exSmall = useMediaQuery('@media (max-height: 479px)') && isMaking;

  return (
    <>
      <Slide direction="down" in={exSmall} unmountOnExit>
        <div className={cn.rootSmall}>
          <AccountIcon />
        </div>
      </Slide>
      <Slide direction="down" in={!exSmall} unmountOnExit>
        <div className={cn.root}>
          <AppBar position="fixed" elevation={0}>
            <Toolbar className={cn.toolbar}>
              <Typography
                variant="h6"
                component={Link('/')}
                className={cn.logo}
              >
                <img
                  src={require('../resources/logo.png')}
                  height={36}
                  alt="HackforPlay"
                />
              </Typography>
              <div className={cn.blank} />
              <Tooltip
                title={<I ja="トップ" en="Home" />}
                classes={{ tooltip: cn.tooltip }}
              >
                <Button
                  component={Link('/')}
                  className={classNames(cn.separator, cn.contrastButton)}
                >
                  <Home />
                  <span className={cn.buttonLabel}>
                    <JA>トップ</JA>
                    <EN>Home</EN>
                  </span>
                </Button>
              </Tooltip>
              {isInOfficialWork ? null : (
                <Tooltip
                  title={<I ja="あそびかた" en="How to Play" />}
                  classes={{ tooltip: cn.tooltip }}
                >
                  <Button
                    component={Link('/contents/tutorial')}
                    className={classNames(cn.separator, cn.contrastButton)}
                  >
                    <Beginner />
                    <span className={cn.buttonLabel}>
                      <JA>あそびかた</JA>
                      <EN>How to Play</EN>
                    </span>
                  </Button>
                </Tooltip>
              )}
              <Tooltip
                title={<I ja="ステージをつくる" en="Create Game" />}
                classes={{ tooltip: cn.tooltip }}
              >
                <Button
                  component={Link('/make')}
                  className={classNames(cn.separator, cn.contrastButton)}
                >
                  <Build />
                  <span className={cn.buttonLabel}>
                    <JA>ステージをつくる</JA>
                    <EN>Create Game</EN>
                  </span>
                </Button>
              </Tooltip>
              <Tooltip
                title={<I ja="みんなのステージ" en="Play Games" />}
                classes={{ tooltip: cn.tooltip }}
              >
                <Button
                  component={Link('/lists')}
                  className={classNames(cn.separator, cn.contrastButton)}
                  data-cy="lists"
                >
                  <VideogameAsset />
                  <span className={cn.buttonLabel}>
                    <JA>みんなのステージ</JA>
                    <EN>Play Games</EN>
                  </span>
                </Button>
              </Tooltip>
              <Tooltip
                title={<I ja="フェス" en="Fes" />}
                classes={{ tooltip: cn.tooltip }}
              >
                <Button
                  component={Link('/fes')}
                  className={classNames(cn.separator, cn.contrastButton)}
                >
                  <People />
                  <span className={cn.buttonLabel}>
                    <JA>フェス</JA>
                    <EN>Fes</EN>
                  </span>
                </Button>
              </Tooltip>
              {auth.userInfo ? <div className={cn.separator} /> : null}
              <AccountIcon />
            </Toolbar>
          </AppBar>
        </div>
      </Slide>
    </>
  );
}

const useStylesAccountIcon = makeStyles(theme => ({
  avatar: {
    cursor: 'pointer',
    marginLeft: theme.spacing(),
    marginRight: theme.spacing()
  }
}));

const badgeClasses = {
  badge: style({
    top: 4,
    right: 8
  })
};

const unreadState = selector<INotificationDocument | undefined>({
  key: 'unreadState',
  get: ({ get }) => {
    const id = get(unreadIdAtom);
    return id ? get(notificationAtom(id)) : undefined;
  }
});

function AccountIcon() {
  const cn = useStylesAccountIcon();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();
  const [openSignInDialog, setOpenSignInDialog] = React.useState(false);
  const auth = useSelector(state => state.auth);
  const authUser = useSelector(getAuthUser);

  const dispatch = useDispatch();

  const handleOpenSignInDialog = React.useCallback(() => {
    setOpenSignInDialog(true);
  }, []);
  const handleCloseSignInDialog = React.useCallback(() => {
    setOpenSignInDialog(false);
  }, []);
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    },
    []
  );
  const handleClose = React.useCallback(() => {
    setAnchorEl(undefined);
  }, []);
  const signOut = React.useCallback(() => {
    dispatch(actions.auth.signOut());
    handleClose();
  }, []);

  const unreadItem = useRecoilValue(unreadState);
  const isShowingUnreadBadge =
    (authUser?.notificationOpenedAt?.toMillis() || 0) < // 最後にお知らせを開いた時刻よりも後に
    (unreadItem?.createdAt?.toMillis() || 0); // 未読のお知らせが届いたならば

  const exSmall = useMediaQuery('@media (max-height: 479px)');

  return (
    <>
      {auth.userInfo ? (
        // アイコンアバター
        <Badge
          color="primary"
          variant="dot"
          invisible={!isShowingUnreadBadge}
          classes={badgeClasses}
        >
          <Avatar
            aria-owns={anchorEl ? 'simple-menu' : undefined}
            aria-haspopup="true"
            className={cn.avatar}
            src={authUser?.iconUrl || defaultIconUrl}
            onClick={handleClick}
          />
        </Badge>
      ) : (
        <Button
          variant="contained"
          color="primary"
          size={exSmall ? 'small' : 'medium'}
          aria-owns={anchorEl ? 'simple-menu' : undefined}
          aria-haspopup="true"
          onClick={handleOpenSignInDialog}
          disabled={!auth.initialized}
        >
          <Person />
          <JA>ログイン</JA>
          <EN>Log in</EN>
        </Button>
      )}
      <Popover
        id="simple-menu"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {auth.userInfo ? (
          <MenuItem
            component={Link(`/users/${auth.userInfo.uid}`)}
            onClick={handleClose}
          >
            <JA>自分のステージ</JA>
            <EN>My Works</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link(`/notifications`)} onClick={handleClose}>
            <Badge
              color="primary"
              variant="dot"
              invisible={!isShowingUnreadBadge}
            >
              <JA>お知らせ</JA>
              <EN>Notifications</EN>
            </Badge>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link('/plans')} onClick={handleClose}>
            <JA>プラン</JA>
            <EN>Membership</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link('/payments')} onClick={handleClose}>
            <JA>お支払い方法</JA>
            <EN>Billing</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link('/preferences')} onClick={handleClose}>
            <JA>せってい</JA>
            <EN>Settings</EN>
          </MenuItem>
        ) : null}
        <MenuItem component={Link('/videos/season_1')} onClick={handleClose}>
          <JA>動画を見る</JA>
          <EN>Videos</EN>
        </MenuItem>
        {auth.userInfo ? (
          <MenuItem component={Link('/series')} onClick={handleClose}>
            <JA>シリーズをつくる</JA>
            <EN>Create Series</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link('/histories')} onClick={handleClose}>
            <JA>あそんだステージ</JA>
            <EN>History</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link('/generative-ai')} onClick={handleClose}>
            <JA>生成AI</JA>
            <EN>Generative AI</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <MenuItem component={Link('/agents')} onClick={handleClose}>
            <JA>AI改造</JA>
            <EN>AI Agents</EN>
          </MenuItem>
        ) : null}
        {auth.userInfo ? (
          <>
            <Divider />
            <MenuItem onClick={signOut}>
              <JA>ログアウト</JA>
              <EN>Log out</EN>
            </MenuItem>
          </>
        ) : null}
      </Popover>
      <SignInDialog open={openSignInDialog} onClose={handleCloseSignInDialog} />
    </>
  );
}
